<template>
  <div class="category_brand">
    <div class="title">热销品牌库</div>
    <div class="title2">{{title}}</div>
    
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-grid :column-num="4" v-if="!nodata">
          <van-grid-item v-for="(item, index) in list" :key="index" :icon="item.logo" :text="item.name" />
        </van-grid>
      </van-list>
    
    <van-empty v-if="nodata" description="暂无品牌" />
  </div>
</template>
<script>
export default {
  name: "CategoryBrand",
  data() {
    return {
      id: 0,
      list: [],
      offset: 0,
      size: 40,
      loading: false,
      finished: false,
      nodata: false,
      title: '',
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    this.init();
  },
  methods: {
    init() {
      console.log("init");
    },
    onLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_category_brand",
          _that.$qs.stringify({
            offset: _that.offset,
            size: _that.size,
            id: _that.id
          })
        )
        .then( res => {
          console.log(res);
          _that.loading = false;
          _that.finished = false;
          _that.nodata = false;
          if (res.data.code == 100000) {
            if(_that.offset == 0) {
              _that.title = res.data.title;
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if (res.data.data.length < _that.size) {
              _that.finished = true;
            }
            _that.offset += res.data.data.length;
          }else {
            if (_that.offset == 0) {
              _that.finished = true;
              _that.nodata = true;
            }else {
              _that.finished = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          if (_that.offset == 0) {
            _that.finished = true;
            _that.nodata = true;
          }else {
            _that.finished = true;
          }
        })
    },
  }
}
</script>
<style lang="less">
.category_brand {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .title {
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .title2 {
    margin: 10px 10px 0 10px;
    border-radius: 6px 6px 0 0;
    background-color: #BDBDBD;
    text-align: center;
    color: #fff;
    height: 24px;
    line-height: 24px;
  }
}
</style>